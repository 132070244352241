<template>
  <div class="list-maxbox">
    <div class="img-box">
      <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/pcProject/infotmation_but.png">
    </div>
    <div class="content-box">
      <div class="item" v-for="(item, index) in dataList" :key="index" @click="toDetail(item.id, index)">
        <div class="item-left">{{item.title}}</div>
        <div class="item-right">{{ item.created_time | secondFormat('LL') }}</div>
      </div>
    </div>
    <el-pagination
        background
        align="center"
        layout="prev, pager, next"
        @current-change="handleCurrent"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "list",
  data () {
    return{
      parms:{
        start: 0,
        limit: 10
      },
      total: 0,
      dataList: []
    }
  },
  mounted() {
    this.$log({
      type: 2,
      topic: "ymp.infomation",
      id: "ymp.infomation",
    });
    this.postList();
  },
  methods:{
    //运抵达资讯接口
    async postList() {
      let data = await this.$store.dispatch("API_freight/postList", this.parms);
      console.log(data);
      this.total = data.total;
      this.dataList = data.data
    },
    handleCurrent (index) {
      this.parms.start = index-1;
      this.postList();
    },
    toDetail (id,index) {
      this.$router.push({
        path:'/infoDetail',
        query:{
          id: id,
          index: index
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .list-maxbox{
    width: 100%;
    height: 100vh;
    background: #FBFCFA;
    padding-bottom: 32px;
    box-sizing: border-box;
    overflow-x: hidden;
    .img-box{
      width: 100%;
      height: 252px;
    }
    .content-box{
      width: 1300px;
      margin: 32px auto 32px;
      padding: 25px 24px;
      box-sizing: border-box;
      background: #FFFFFF;
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 0;
        box-sizing: border-box;
        cursor: pointer;
        border-bottom: 1px solid #F5F7FA;
        .item-left{
          width: 80%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }
  }
</style>